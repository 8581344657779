.love {
  padding: 18px 36px;

  p {
    padding: 8px 0;
  }

  &__images {
    width: 100%;
    overflow: hidden;

    display: grid;

    grid-template-columns: 1fr 1fr;
    gap: 16px;
    align-items: center;
    justify-content: center;

    img {
      max-width: 600px;
      max-height: 600px;
    }

    @media (max-width: 960px) {
      display: flex;
      flex-direction: column;
    }
  }
}